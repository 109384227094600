import React from 'react'
import CategoryNav from '../components/categories/nav'
import Logo from '../components/layout/logo'
import { Container, Row, Col } from 'react-bootstrap'

class ThankYouPage extends React.Component {
  render() {
    return (
      <>
        <Container className="py-5">
          <Row className="align-items-center">
            <Col md="3" className="pb-3 pb-md-0">
              <Logo />
            </Col>
            <Col md={{ span: 7, offset: 2 }}>
              <h3 className="hu h4">Thanks for signing up!</h3>
              <p className="hu h4">See you on the yard!</p>
            </Col>
          </Row>
        </Container>
        <CategoryNav />
      </>
    )
  }
}

export default ThankYouPage
